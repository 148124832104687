/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
    .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, 
    .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, 
    .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
    .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
    {
        display: none !important;
    } 
}