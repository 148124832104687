.push-lottery-result-page .type-ticket-button{
    
    background-color: rgb(226, 230, 235);
    border: 1px solid black;
    color: black;
}
.push-lottery-result-page .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
    background-color: #397BE5!important;
    border: 1px solid rgb(28, 88, 184)!important;
}
.term-input{
    max-width: 200px;
    width: 50%;
}
.border-red{
    border: 1px solid red;
}
.border-orange{
    border: 1px solid orange!important;
}
.number-2{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 5px;
}
.number-3{
    min-width: 56px;
    
}
.input-jackpot{
    width: 100%;
    max-width: 258px;
}